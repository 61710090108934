<template>
  <FormContent :selected='selected' class='pa-6'>
    <DataCrud
      title=' '
      entityName='Propriedades'
      addFunctionName='+ Propriedade'
      :tableHeader='header'
      :tableData='content'
      :enableAddFunction='true'
      :enableEditFunction='true'
      :showFormDialog='true'
      :enableRemoveFunction='true'
      v-on:formDelete="formDelete"
      v-on:changeShowDialog="changeShowDialog"
      v-on:editAction="editAction"
      v-on:showAddForm="showAddForm"
      dialogWidth='900px'>
    </DataCrud>
      <PropertyDataForm :form="form" :showDialog=showDialog :key="formKey" :types="types" :formCancel="formCancel" :formSubbmit="formSubbmit"/>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '@/components/template/crud/DataCrud';
import PropertyDataForm from '@/views/productTypes/forms/PropertyDataForm';

export default Vue.extend({

  components: {
    FormContent,
    DataCrud,
    PropertyDataForm,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Propriedades',
    },
    content: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected: false,
    header: [
      { text: 'Código', align: 'start', value: 'code' },
      { text: 'Nome da propriedade', value: 'name' },
      { text: 'Tipo', value: 'type' },
    ],
    base_form: {
      itemId: null,
      code: '',
      name: '',
      type: '',
      searchable: true,
      multi_selectable: true,
      critical: true,
      filtered: true,
    },
    form: {
      itemId: null,
    },
    oldData: {},
    errors: {
      code: true,
      name: true,
      type: true,
    },
    showDialog: false,
    formKey: null,
    types: [
      'shortText', 'richText', 'number', 'checkbox', 'date',
    ],
  }),
  mounted() {
    this.form = { ...this.base_form };
    this.updateFormKey();
  },
  methods: {
    editAction(item) {
      this.oldData = { ...item };
      this.form = item;
      this.showDialog = true;
    },
    showAddForm() {
      this.updateFormKey();
      this.form = {};
      this.showDialog = true;
    },
    formSubbmit() {
        this.$emit('addProperty', this.form);
        this.form = this.base_form;
        this.updateFormKey();
        this.showDialog = false;
    },
    formCancel() {
      this.form = Object.assign(this.form, this.oldData);
      this.showDialog = false;
    },
    formDelete(item) {
      if (this.form.itemId !== null) {
        this.$emit('deleteProperty', this.form);
        this.showDialog = false;
      }
      if (item) {
        this.$emit('deleteProperty', item);
      }
    },
    changeShowDialog(show) {
      this.showDialog = show;
    },
    updateFormKey() {
      this.formKey = Math.floor(Math.random() * 1000000000) + 1;
    },
  },
});

</script>
