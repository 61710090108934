<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3'>
      <v-col style="margin-right: 5%" xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput v-model='form.code' :disabled="!!$route.params.id" label='Código*'
                        placeHolder='Informe o código do tipo de produto'
                        @focusout="validate('code')"
                        input-type='text'
                        labelWidth='40%'
                        :validationRules='[validationRules.code]'/>
      </v-col>
      <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <v-row class='row-space'>
          <div class='font-label' style='width: 20%'>
            Status*
          </div>
          <div style='width: 80%'>
            <v-combobox placeholder="Selecione o Status do Tipo de Produto"
                        validate-on-blur filled dense
                        item-text="name"
                        item-value="code"
                        :items='options'
                        :error="(form.status && form.status.code === 'DRAFT') || (form.status && form.status.code !== 'DRAFT' && previewStatus !== 'DRAFT')"
                        :error-messages="(form.status && form.status.code === 'DRAFT') || (form.status && form.status.code !== 'DRAFT' && previewStatus !== 'DRAFT') ? 'true' : null"
                        v-model='form.status'>
              <template v-slot:item="{ index, item }">
                 <span :style="`color: ${item.color}`">
                   {{ item.name }}
                 </span>
              </template>
              <template v-slot:selection="{ attrs, item }">
                <span :style="`color: ${item.color}`">
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:message>
             <span v-if="form.status && form.status.code === 'DRAFT'" style="color:#00c9ff"> Quando todas as informaçãoes obrigatórias<br>
                forem preenchidas o status do cadastro<br>
                poderá ser alterado para publicado ou não<br>
                publicado
             </span>
              <span v-if="form.status && form.status.code === 'UNPUBLISHED'" style="color:#00c9ff">Este tipo de produto não está sendo<br>
                utilizado nos canais de venda neste<br>
                momento, podendo ser editado ou excluido<br>
                a qualquer momento
              </span>
                <span v-if="form.status && form.status.code === 'PUBLISHED'" style="color:#00c9ff">Este tipo de produto está sendo<br>
                utilizado nos canais de venda neste momento. Altere<br>
                o status para "não publicado" para editar ou<br>
                excluir o cadastro.
              </span>
              </template>
            </v-combobox>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col style="margin-right: 5%" xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput @focusout="validate('name')" v-model='form.name' label='Nome*' placeHolder='Informe o nome do tipo de produto'
                        input-type='text' labelWidth='40%' :validationRules='[validationRules.name]'/>
      </v-col>
      <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <SwitchInput v-model='form.showDimensionTable' label='Apresentar tabela de dimensões?'
                     labelWidth='40%'/>
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';

export default Vue.extend({

  components: {
    FormContent,
    TextFieldInput,
    SwitchInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Informações Gerais',
    },
    previewStatus: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    statusList: {
      type: Array,
    },
  },
  data: () => ({
    selected: false,
    colLabelWidth: '200px',
    colLabelHAlign: 'left',
  }),
  methods: {
    validate(field) {
      this.$emit('validateRequired', 'GeneralDataForm', field, this.form[field]);
    },
  },
  computed: {
    options() {
      if (this.previewStatus === 'DRAFT') {
        if (!this.form.code || !this.form.name) {
          return [
            { code: 'DRAFT', name: 'Em Rascunho', color: 'orange' },
          ];
        }
        return [
          { code: 'DRAFT', name: 'Em Rascunho', color: 'orange' },
          { code: 'PUBLISHED', name: 'Publicado', color: 'green' },
          { code: 'UNPUBLISHED', name: 'Não Publicado', color: 'gray' },
        ];
      }
      return [
        { code: 'PUBLISHED', name: 'Publicado', color: 'green' },
        { code: 'UNPUBLISHED', name: 'Não Publicado', color: 'gray' },
      ];
    },
  },
});

</script>
