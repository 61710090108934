<template>
  <v-container>
    <v-row class='data-list-info' v-if='!!info'>
      <span v-html="info"></span>
    </v-row>
    <v-row style='margin-bottom: 1.90rem;'>
      <DataFilter @filter="emitFilter" v-if='showFilter' :search='search' :filters='filters'/>
    </v-row>
    <v-row style='padding-top: 0;'>
      <v-data-table class='table-default' :item-key='itemKey' :show-expand='showExpand' :single-expand='singleExpand'
                    :loading='isLoading' loading-text="Carregando dados... Por favor, aguarde..." :headers='tableHeader'
                    :items='tableData' :server-items-length="serverItemsLength" :search='search' :items-per-page='10'
                    @update:items-per-page="changeItemsPerPage" @update:page="changePage" hide-default-header
                    :item-class="tableRowClass" :footer-props="{
          'items-length': 100,
          'items-per-page-text': 'Itens por página',
          'items-per-page-options': [10, 20, 50],
          'pageText': '{0}-{1} de {2}'
        }">
        <template v-slot:item.data-table-expand="{ expand, isExpanded }">
          <v-btn outlined fab dark color="primary" x-small @click="expand(!isExpanded)">
            <v-icon dark class="table-expander">
              mdi-plus
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers }">
          <td class="expanded-data-table-container" :colspan="headers.length" icon="mdi-plus">
            <v-data-table id="tableExpanded" class='table-default' :item-key='itemKey' :loading='isLoading'
                          loading-text="Carregando dados... Por favor, aguarde..." :headers='headers' :items='tableData'
                          :hide-default-footer="true" :hide-default-header="true" :item-class='expandedTableRowClass'>
              <template v-slot:[`item.actions`]='{ item }'>
                <v-layout justify-start>
                  <v-tooltip left v-for='func in columnFunctions' :key='func.name'>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :size="20" :disabled='func.validateDeleteButton ? func.validateDeleteButton(item) : false'
                              :color='func.color' @click='func.function(item)' v-bind="attrs" v-on="on">
                        {{ func.icon }}
                      </v-icon>
                    </template>
                    <span>{{ func.name }}</span>
                  </v-tooltip>
                </v-layout>
              </template>
              <template v-slot:no-data>
                <div class="pt-10 pb-10">
                Nenhum resultado encontrado
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="h in headers" :class="h.class" :key="h.text">
                <a @click="sort(h.value, h.sortable)" style="color: black">
                  <v-layout v-if='!h.align || h.align === "start"' justify-start>
                    <span>{{ h.text }}</span>
                    <v-icon color='black' v-if="sortable[h.value]">
                      {{ sortable[h.value] === 'ASC' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                  </v-layout>
                  <v-layout v-if='h.align === "center"' justify-start>
                    <span>{{ h.text }}</span>
                    <v-icon color='black' v-if="sortable[h.value]">
                      {{ sortable[h.value] === 'ASC' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                  </v-layout>
                  <v-layout v-if='h.align === "end"' justify-start>
                    <span>{{ h.text }}</span>
                    <v-icon color='black' v-if="sortable[h.value]">
                      {{ sortable[h.value] === 'ASC' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                  </v-layout>
                  <span>

                  </span>
              </a>
            </th>
          </tr>
          </thead>
        </template>
        <template v-for='dec in columnDecorators' v-slot:[`item.${dec.column}`]='{ item }'>
          <v-layout :style="'width: ' + dec.width ? dec.width : 'auto'" justify-start :key='dec.column'>
            <span v-if='dec.formatterImput' :key='dec.column'>
              <TextFieldInput @input="changeFormatterInput($event, item, dec.formatterImput.param)" :remove-label="true"
                              v-model="item[dec.formatterImput.param]" :inputType='dec.formatterImput.type' :readOnly="dec.disabled"/>
            </span>
            <v-icon :title="dec.iconTitleFunction ? dec.iconTitleFunction(item) : ''"
                    v-else-if='dec.iconFunction && dec.iconColorFunction' :key='dec.column'
                    :color='dec.iconColorFunction(item)'>
              {{ dec.iconFunction(item) }}
            </v-icon>
            <v-chip v-else-if="dec.formatterFunction && !dec.type"
                    :title="dec.titleFunction ? dec.titleFunction(item) : ''"
                    :color='dec.chipBackgroundColorFunction ? dec.chipBackgroundColorFunction(item) : null'>
              <span :title="item[dec.column]" :style='"color:" + (dec.chipTextColorFunction ? dec.chipTextColorFunction(item) : "black")'>
                {{ dec.formatterFunction(item) }}
              </span>
            </v-chip>
            <v-chip v-else-if="dec.chipBackgroundColorFunction && !dec.chipTextColorFunction && !dec.type"
                    :key='dec.column' :color='dec.chipBackgroundColorFunction(item)'>
              {{ item[dec.column] }}
            </v-chip>
            <v-chip v-else-if="dec.chipBackgroundColorFunction && dec.chipTextColorFunction && !dec.type"
                    :key='dec.column' :color='dec.chipBackgroundColorFunction(item)'
                    :text-color='dec.chipTextColorFunction(item)'>
              {{ item[dec.column] }}
            </v-chip>
            <span :class="[dec.ellipsis ? 'format-text' : '']" :title="item[dec.column]" v-else :style='"color:" + (dec.chipTextColorFunction ? dec.chipTextColorFunction(item) : "black") + (dec.fontBold ? "; font-weight: bold;" : "")'>
              {{ dec.formatterFunction ? dec.formatterFunction(item) : item[dec.column] }}
            </span>
          </v-layout>
        </template>
        <template v-if="showTotalLine" slot="body.append">
          <tr v-html="totalLine" v-for="totalLine in totalLines" :key="totalLine">
          </tr>
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-layout justify-start>
            <v-tooltip left v-for='func in columnFunctions' :key='func.name'>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="!hasSlotActionIcon" :size="20" :disabled='func.validateDeleteButton ? func.validateDeleteButton(item) : false'
                        :color='func.color' @click='func.function(item)' v-bind="attrs" v-on="on">
                  {{ func.icon }}
                </v-icon>
                <slot name="action-icon" :item="item" ></slot>
              </template>
              <span>{{ func.name }}</span>
            </v-tooltip>
          </v-layout>
        </template>
        <template v-slot:no-data>
          <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>

import DataFilter from '@/components/template/list/DataFilter';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';

export default {
  components: {
    DataFilter,
    TextFieldInput,
  },
  props: {
    sortable: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    tableHeader: {
      type: Array,
      required: true,
      default: () => [],
    },
    serverItemsLength: {
      type: Number,
      default: -1,
    },
    tableData: {
      type: Array,
      required: false,
      default: () => [],
    },
    columnDecorators: {
      type: Array,
      required: false,
      default: () => [],
    },
    columnActions: {
      type: Array,
      required: false,
      default: () => [],
    },
    columnFunctions: {
      type: Array,
      required: false,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalLines: {
      type: Array,
      required: false,
      default: () => [],
    },
    justifyCenter: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: String,
      required: false,
      default: '',
    },
    orderItems: {
      type: Object,
      required: false,
    },
    orderOptions: {
      type: Object,
      required: false,
    },
    expandedHeaders: {
      type: Array,
      required: false,
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemKey: {
      type: String,
      required: false,
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    hasSlotActionIcon() {
      return !!this.$scopedSlots['action-icon'];
    },
  },
  methods: {
    tableRowClass(item) {
      const tableRowClassName = 'table-default-cell';
      return this.showExpand ? `${tableRowClassName} ${this.expandedTableRowClass()}` : tableRowClassName;
    },
    expandedTableRowClass(item) {
      return 'expanded-row';
    },
    changeFormatterInput(value, item, param) {
      this.$emit('changeFormatterInput', { item, param, value });
    },
    sort(data, sortable) {
      if (sortable) {
        if (!this.sortable[data]) {
          this.sortable[data] = 'ASC';
        } else if (this.sortable[data] === 'ASC') {
          this.sortable[data] = 'DESC';
        } else if (this.sortable[data] === 'DESC') {
          delete this.sortable[data];
        }
        this.$forceUpdate();
        this.$emit('changeSort', this.sortable);
      }
    },
    changeItemsPerPage(data) {
      this.$emit('changeItemsPerPage', data);
    },
    emitFilter(data) {
      this.$emit('emitFilter', data);
    },
    changePage(data) {
      this.$emit('changePage', data);
    },
  },
  data() {
    return { search: '' };
  },
  created() {
    if (this.columnFunctions.length > 0) {
      this.tableHeader.push({
        text: 'Ações',
        align: 'center',
        value: 'actions',
      });
    }
    if (this.tableHeader.length > 0) {
      this.tableHeader.forEach((header) => {
        header.class = 'my-header-style';
      });
    }
  },
};

</script>
