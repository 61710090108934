<template>
  <v-row class="d-flex justify-start align-start pa-8" style="height: 100%; width: 100%; background-color:#EDEDED !important;">
    <v-col cols="12">
      <Alert :open="backDialogOpen" :message="backAlertMessage" :screenTitle="alertScreenTitle" v-on:close="closeBackDialog" v-on:exit="exitForm" />
    </v-col>
    <v-col cols="4" lg="3">
      <v-row
        class="pa-4 pl-0"
        :class="{ 'step-controller-padding-xl': $vuetify.breakpoint.xl, 'step-controller-padding-lg': $vuetify.breakpoint.lg }"
      >
        <v-col class="d-flex align-center">
          <v-btn icon color="#07bee7" large @click.prevent.stop="cancelForm()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="form-step-controller-title">
            {{ title }}
          </span>
        </v-col>
        <v-col v-for="(menu, index) in menus" :key="`tab-${index}`" cols="12">
          <v-divider light></v-divider>
          <v-card elevation="0" color="rgb(237, 237, 237)">
            <v-card-text class="pa-0 pt-4 d-flex">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="form_step_controller_row_icon"
                    :color="menu.iconColor"
                    @click.prevent.stop="selectTab(index)"
                  >
                    {{ menu.icon }}
                  </v-icon>
                </template>
                <span>{{ menu.name }}</span>
              </v-tooltip>
              <v-btn v-if="!hide" :color="menu.fontColor" :disabled="!!menu.disabled" text @click.prevent.stop="selectTab(index)">
                {{ menu.name }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-divider light></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="8" lg="9" xl="9">
      <form style="max-height: 100%; border-radius: 15px" class="mt-8 pa-6 background-default">
        <slot></slot>
      </form>
      <v-skeleton-loader :loading="loading" type=" actions">
        <div class="d-flex justify-space-between mt-0 pa-4 pt-8">
          <span>
            <v-btn
              v-if="showActionButtons && isFirstTab()"
              depressed
              large
              color="#07BEE7"
              class="mr-4 form_step_controller_action_button"
              outlined
              @click="previousTab()"
              >VOLTAR</v-btn
            >
            <v-btn
              v-if="showActionButtons && isLastTab()"
              depressed
              large
              color="#07BEE7"
              class="form_step_controller_action_button"
              outlined
              @click="nextTab()"
              >PROSSEGUIR</v-btn
            >
          </span>
          <span>
            <slot v-if="canSubbmit()" name="action"></slot>
            <v-btn
              style="color: #FFFFFF"
              v-if="showCustomButton && editing"
              large
              color="#30B333"
              class="mr-4 form_step_controller_action_button"
              @click="customAction()"
              :disabled="customButtonDisabled"
            >
              {{ customButtonLabel }}
            </v-btn>
            <v-btn
              v-if="showActionButtons && showDeleteActionButton && !readOnly"
              depressed
              large
              color="#07BEE7"
              class="mr-4 form_step_controller_action_button"
              @click="deleteForm()"
              :disabled="!editing"
              outlined
            >
              EXCLUIR
            </v-btn>
            <v-btn
              v-if="showActionButtons && !readOnly"
              depressed
              large
              color="#07BEE7"
              class="mr-4 form_step_controller_action_button"
              @click.prevent.stop="cancelForm()"
              outlined
            >
              CANCELAR
            </v-btn>
            <v-btn
              v-if="showActionButtons && !readOnly"
              depressed
              large
              color="#07BEE7"
              class="form_step_controller_action_button"
              @click="subbmitForm()"
              :disabled="(!canSubbmit() && !canSubbmitEspecificatio) || blockSave"
              outlined
            >
              SALVAR
            </v-btn>
            <slot class="ml-4" name="endActions"> </slot>
          </span>
        </div>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import Alert from '@/components/template/alert/Alert';
import { routerBack } from '@/service/kplaceRouter';

export default Vue.extend({
  components: {
    Alert,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    canSubbmitEspecificatio: {
      type: Boolean,
      default: false,
    },
    blockSave: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
    },
    stepStatus: {
      type: Array,
      default: () => [],
    },
    steps: {
      type: Object,
      default: () => ({}),
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showDeleteActionButton: {
      type: Boolean,
      default: true,
    },
    showCustomButton: {
      type: Boolean,
      default: false,
    },
    customButtonLabel: {
      type: String,
      default: 'Custom Action',
    },
    customButtonDisabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    backAlertMessage: {
      type: String,
      required: false,
    },
    alertScreenTitle: {
      type: String,
      required: false,
    },
    formModified: {
      type: Boolean,
      default: false,
    },
    disabledTabCount: {
      type: Number,
      default: 0,
    },
    isSaveNextTab: {
      type: Boolean,
      default: false,
    },
    actualTab: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    actualTab(newTab) {
      this.switchTab(newTab);
    },
  },
  computed: {
    hide() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return false;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
  },
  data() {
    return {
      tabs: [],
      menus: [],
      currentTab: 0,
      totalTabs: 0,
      backDialogOpen: false,
    };
  },

  mounted() {
    let count = 0;
    this.tabs = this.$slots.default;
    this.totalTabs = this.tabs.length;
    this.totalTabs -= this.disabledTabCount;

    this.tabs.forEach((tab) => {
      this.menus.push({
        name: tab.componentInstance.title,
        disabled: tab.componentInstance.disabled,
        fontColor: '#6C757D',
        icon: tab.componentInstance.icon || 'mdi-cube-outline',
        iconColor: '#6C757D',
      });
      if (tab.componentInstance.selected === true) {
        this.currentTab = count;
      }
      count += 1;
    });

    // Select first tab if none is marked selected
    if (this.currentTab === -1 && this.totalTabs > 0) {
      this.tabs[0].componentInstance.selected = true;
      this.currentTab = 0;
    }
    this.switchTab(this.currentTab);
  },
  methods: {
    openBackDialog() {
      this.backDialogOpen = true;
    },
    closeBackDialog() {
      this.backDialogOpen = false;
    },
    exitForm() {
      this.backDialogOpen = false;
      routerBack();
    },
    previousTab() {
      if (this.currentTab > 0) {
        this.switchTab(this.currentTab - 1);
      }
    },
    nextTab() {
      if (this.currentTab < this.totalTabs - 1) {
        this.switchTab(this.currentTab + 1);
      }
    },
    selectTab(index) {
      this.switchTab(index);
    },
    switchTab(index) {
      const arStepKeys = Object.keys(this.steps);
      if (!this.tabs[index].componentInstance.disabled || !this.steps[arStepKeys[index]].disabled) {
        let count = 0;
        this.tabs.forEach((tab) => {
          let icon = 'check';
          let color = 'green';
          let isOpenned = false;
          if (arStepKeys.length > 0) {
            icon = arStepKeys.length > count && !this.steps[arStepKeys[count]].status.valid && !this.readOnly ? 'alert' : 'check';
            color = arStepKeys.length > count && !this.steps[arStepKeys[count]].status.valid && !this.readOnly ? 'orange' : 'green';
            this.steps[arStepKeys[count]].status.oppened =
              this.steps[arStepKeys[count]].step - 1 < index || this.steps[arStepKeys[count]].status.oppened;
            isOpenned = this.steps[arStepKeys[count]].status.oppened || this.steps[arStepKeys[count]].step - 1 < index;
            this.steps[arStepKeys[index]].status.oppened = true;
          }
          tab.componentInstance.selected = false;
          this.menus[count].icon = isOpenned ? `mdi-${icon}-circle-outline` : tab.componentInstance.icon || 'mdi-cube-outline';
          this.menus[count].iconColor = isOpenned ? color : '#6C757D';
          this.menus[count].fontColor = '#6C757D';
          this.menus[count].disabled = false;
          count += 1;
        });

        this.currentTab = index;
        this.tabs[index].componentInstance.selected = true;
        this.menus[index].icon = this.tabs[index].componentInstance.icon || 'mdi-cube-outline';
        this.menus[index].iconColor = '#F44FE3';
        this.menus[index].fontColor = '#000000';
      }
    },
    subbmitForm() {
      this.$emit('formSubbmit');
      if (this.$props.isSaveNextTab) this.nextTab();
    },
    cancelForm() {
      if (this.formModified) {
        this.openBackDialog();
      } else {
        this.exitForm();
      }

      this.$emit('formCancel');
    },
    deleteForm() {
      this.$emit('formDelete');
    },
    customAction() {
      this.$emit('customAction');
    },
    isFirstTab() {
      return this.currentTab > 0;
    },
    isLastTab() {
      return this.currentTab < this.totalTabs - 1;
    },
    canSubbmit() {
      const arStepKeys = Object.keys(this.steps);
      for (let i = 0; i < arStepKeys.length; i += 1) {
        if (!this.steps[arStepKeys[i]].status.valid) {
          return false;
        }
      }
      return true;
    },
  },
});
</script>

<style scoped>
.background-default {
  background-color: rgba(245, 245, 246, 255) !important;
}

.form-step-controller-title {
  text-align: left;
  font: normal normal bold 22px/24px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #190804;
  opacity: 1;
  min-width: 200px;
}

.form_step_controller_row_icon {
  height: 23px;
  margin-top: 7px;
}

.form_step_controller_action_button {
  text-align: left;
  font: normal normal medium 15px/18px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}
</style>
