<template>
  <FormContent :selected="selected" class="pa-6">
    <div class="custom-font">Selecione abaixo as categorias relacionadas a este tipo de produto.</div>
    <div class="custom-font mb-6">Essa Informação é obrigatória para o cadastro.</div>
    <div class="user-row">
      <div>Categoria*</div>
      <div>
          <CustomSelect
            :item-text="formatItem"
            item-value="id"
            label-width="2%"
            :multiple="true"
            :nofilter="true"
            :search="fetchCategory"
            v-model='form.categories'
            :clearOnOpen="false"
          />
      </div>
    </div>
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import { fetchCategory } from '@/service/category/category-service';
import CustomSelect from '@/components/custom-select/CustomSelect';

export default Vue.extend({
  components: {
    CustomSelect,
    FormContent,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'CATEGORIAS RELACIONADAS',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selected: false,
}),
  methods: {
    async fetchCategory(param, filter, itemsPerPage) {
      const categories = await fetchCategory(filter, itemsPerPage, 'name', 'ASC', true);
      if (this.form.categories?.length > 0) {
        const promises = this.form.categories.map((id) => fetchCategory(`( id = ${id} )`, itemsPerPage, 'name', 'ASC', true));
        const response = await Promise.all(promises);
        const elements = response.map((item) => item.register[0]);
        categories.register = categories.register.concat(elements);
      }
      return categories;
    },

    formatItem(item) {
      return `${item.id} - ${item.name} - ${item.integrationPlatform}`;
    },
  },
});
</script>
