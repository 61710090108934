<template>
  <FormContent :selected='selected' class='pa-6'>
    <DataCrud
        title=' '
        entityName='Variante'
        addFunctionName='+ Variante'
        :tableHeader='header'
        :tableData='content'
        :enableAddFunction='true'
        :enableEditFunction='false'
        :showFormDialog='true'
        :showDialog='show_dialog'
        :isEditing='form.itemId'
        :enableRemoveFunction='false'
        :persistentCrudDialog='true'
        v-on:formSubbmit="formSubbmit"
        v-on:showAddForm="showAddForm"
        v-on:formCancel="formCancel"
        v-on:formDelete="formDelete"
        v-on:changeShowDialog="changeShowDialog"
        v-on:editAction="editAction"
        dialogWidth='900px'>
      <VariantsDataForm :form="form" :key="formKey" :validation="{
        code: [validateCode],
        name: [validateName],
        values: [validateValues]
      }"/>
    </DataCrud>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '@/components/template/crud/DataCrud';
import VariantsDataForm from '@/views/productTypes/forms/VariantsDataForm';
import notification from '@/service/notification';

export default Vue.extend({

  components: {
    FormContent,
    DataCrud,
    VariantsDataForm,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Variantes',
    },
    content: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected: false,
    header: [
      { text: 'Código', align: 'start', value: 'code' },
      { text: 'Nome da variante', value: 'name' },
      { text: 'Valores', align: 'start', value: 'values' },
    ],
    base_form: {
      itemId: null,
      code: '',
      status: true,
      name: '',
      values: '',
    },
    form: {},
    oldData: {},
    errors: {
      code: true,
      name: true,
      values: true,
    },
    show_dialog: false,
    formKey: null,
  }),
  mounted() {
    this.form = { ...this.base_form };
  },
  methods: {
    editAction(item) {
      this.oldData = { ...item };
      this.form = item;
      this.updateFormKey();
      this.show_dialog = true;
    },
    showAddForm() {
      this.updateFormKey();
      this.form = {};
    },
    formSubbmit() {
      let hasError = false;

      let message = this.validateName(this.form.name);
      if (message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      message = this.validateValues(this.form.values);
      if (!hasError && message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      message = this.validateCode(this.form.code);
      if (message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      if (!hasError) {
        this.$emit('addVariant', this.form);
        this.form = this.base_form;
        this.show_dialog = false;
      }
    },
    formCancel() {
      this.form = Object.assign(this.form, this.oldData);
      this.show_dialog = false;
    },
    formDelete(item) {
      if (this.form.itemId !== null) {
        this.$emit('deleteVariant', this.form);
        this.show_dialog = false;
      }
      if (item) {
        this.$emit('deleteVariant', item);
      }
    },
    changeShowDialog(show) {
      this.show_dialog = show;
    },
    updateFormKey() {
      this.formKey = Math.floor(Math.random() * 1000000000) + 1;
    },
    validateCode(value) {
      return !!value || 'Campo `Código` é Obrigatório.';
    },
    validateName(value) {
      return !!value || 'Campo `Nome` é Obrigatório.';
    },
    validateValues(values) {
      return !!values || 'Campo `Valores` é Obrigatório.';
    },
  },
});

</script>
