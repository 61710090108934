<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-combobox :placeholder="placeHolder"
                validate-on-blur filled dense
                :item-text="labelValue"
                :item-value="itemValue"
                :error="error"
                :error-messages="error_message"
                :items='options'
                :rules="validationRules"
                :clearable="readOnly ? !readOnly : clearable"
                :disabled="disabled"
                :readonly="readOnly"
                @change="change"
                @focusout="focusout"
                v-model='select'/>
  </v-row>
</template>

<script>

export default {
  name: 'ComboboxInput',
  props: {
    label: { type: String, default: '' },
    labelValue: { type: String, default: 'name' },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    paddingLeft: { type: String, default: '0%' },
    placeHolder: { type: String, default: '' },
    validationRules: { type: Array },
    itemText: { type: String },
    itemValue: { type: String },
    value: { type: [Object, Number, String] },
    options: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
  },
  data: () => ({
    error_message: null,
    error: false,
  }),
  methods: {
    focusout() {
      this.$emit('focusout');
    },
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.error = result.hasError;
          this.error_message = result.hasError ? result.message : null;
        }
        this.$forceUpdate();
      }
    },
    change() {
      this.$emit('change');
    },
  },
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign)
        .concat('; padding-left: ')
        .concat(this.paddingLeft);
    },
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.validate(value);
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
</style>
