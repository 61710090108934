<template>
  <FormContent :selected='selected' class='pa-6'>
    <DataCrud
      title=' '
      entityName='Propriedades'
      addFunctionName='+ Propriedade de SKU'
      :tableHeader='header'
      :tableData='content'
      :enableAddFunction='true'
      :enableEditFunction='false'
      :showFormDialog='true'
      :enableRemoveFunction='false'
      v-on:formDelete="formDelete"
      v-on:changeShowDialog="changeShowDialog"
      v-on:editAction="editAction"
      v-on:showAddForm="showAddForm"
      dialogWidth='900px'>
    </DataCrud>
    <v-row>
      <v-dialog v-model='show_dialog' max-width='80%' persistent>
        <v-card class="dialog-content">
          <v-card-title style='padding-top: 20px;'>
            <h1 data-testid='page-title' class='description'>
              Cadastro de Propriedades de SKU
            </h1>
            <v-spacer/>
            <v-btn color='#07BEE7' icon dark @click='formCancel'>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <SKUPropertyDataForm :form="form" :key="formKey" :types="types" :validation="{
        code: [validateCode],
        type: [validateType],
        name: [validateName]
          }"/>
          </v-card-text>
          <v-card-actions style='padding: 0 30px 30px 30px;'>
            <v-btn color='#07BEE7' v-show="false" outlined >Excluir</v-btn>
            <v-spacer/>
            <v-btn color='#07BEE7' @click='formCancel' outlined>Cancelar</v-btn>
            <v-btn color='#07BEE7' @click='formSubbmit' outlined>Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '@/components/template/crud/DataCrud';
import SKUPropertyDataForm from '@/views/productTypes/forms/SKUPropertyDataForm';
import notification from '@/service/notification';

export default Vue.extend({

  components: {
    FormContent,
    DataCrud,
    SKUPropertyDataForm,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Propriedades de SKU',
    },
    content: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected: false,
    header: [
      { text: 'Código', align: 'start', value: 'code' },
      { text: 'Nome da propriedade de SKU', value: 'name' },
      { text: 'Tipo', value: 'type' },
    ],
    base_form: {
      itemId: null,
      code: '',
      name: '',
      type: '',
      searchable: true,
      multi_selectable: true,
      critical: true,
      filtered: true,
    },
    form: {
      itemId: null,
    },
    oldData: {
      itemId: null,
    },
    errors: {
      code: true,
      name: true,
      type: true,
    },
    show_dialog: false,
    formKey: null,
    types: [
      'shortText', 'richText', 'number', 'checkbox', 'date',
    ],
  }),
  mounted() {
    this.form = { ...this.base_form };
  },
  methods: {
    editAction(item) {
      this.updateFormKey();
      this.oldData = { ...item };
      this.form = item;
      this.show_dialog = true;
    },
    showAddForm() {
      this.updateFormKey();
      this.form = {};
      this.show_dialog = true;
    },
    formSubbmit() {
      let hasError = false;

      let message = this.validateCode(this.form.code);
      if (message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      message = this.validateType(this.form.type);
      if (message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      message = this.validateName(this.form.name);
      if (!hasError && message !== true) {
        hasError = true;
        notification(message, 'error');
      }

      if (!hasError) {
        this.$emit('addSku', this.form);
        this.show_dialog = false;
      }
    },
    formCancel() {
      this.form = Object.assign(this.form, this.oldData);
      this.show_dialog = false;
    },
    formDelete(item) {
      if (this.form.itemId !== null) {
        this.$emit('deleteSku', this.form);
        this.show_dialog = false;
      }
      if (item) {
        this.$emit('deleteSku', item);
      }
    },
    changeShowDialog(show) {
      this.show_dialog = show;
    },
    updateFormKey() {
      this.formKey = Math.floor(Math.random() * 1000000000) + 1;
    },
    validateCode(value) {
      return !!value || 'Campo `Código` é Obrigatório.';
    },
    validateType(value) {
      return (!!value && this.types.includes(value)) || 'Campo `Tipo` é Obrigatório.';
    },
    validateName(value) {
      return !!value || 'Campo `Nome` é Obrigatório.';
    },
  },
});

</script>
<style>
.dialog-content {
  background: #ededed 0 0 no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1;
}
</style>
