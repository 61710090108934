<template>
  <v-main class="pa-6">
    <v-row class="margin-fix pa-3">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          v-model="form.code"
          label="Código*"
          placeHolder="Informe o ID/código da propriedade"
          labelWidth="40%"
          :validationRules="[codeRules]"
        />
      </v-col>
      <v-col md="1"><span/></v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextFieldInput
          v-model="form.name"
          label="Nome*"
          placeHolder="Informe o nome para exibição da propriedade"
          labelWidth="20%"
          :validationRules="validation.name"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextFieldInput
          v-model="form.values"
          label="Valores*"
          placeHolder='Digite os valores separados por ","'
          labelWidth="20%"
          :validationRules="validation.values"
        />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Vue from 'vue';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import { codeRules } from '@/validations/validation';

export default Vue.extend({
  components: {
    TextFieldInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cadastro da Variante',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      codeRules,
    };
  },
});
</script>
