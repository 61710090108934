<template>
  <div>
  <v-row class='row-space'>

    <div v-if="!removeLabel" class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-text-field
      :prefix="prefix"
      :placeholder='placeHolder'
      :type='inputType'
      :hide-details="removeLabel"
      v-model='textValue'
      :rules="validationRules"
      :prepend-inner-icon='prependInnerIcon'
      :error="error || err"
      :error-messages="errorMessage"
      :readonly=readOnly
      :disabled="disabled"
      @blur="parseMask(value)"
      @focusout="focusout"
      @change="change"
      validate-on-blur
      filled
      dense
      light
    />
  </v-row>
  <div v-if="maxLength" class="count v-messages theme--light v-messages__message message-transition-enter-to">
    {{ textValue ? textValue.length : 0 }}/ {{ maxLength }}
  </div>
  </div>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'TextFieldInput',
  props: {
    label: { type: String, default: '' },
    removeLabel: { type: Boolean, default: false },
    onlyInteger: { type: Boolean, default: false },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    paddingLeft: { type: String, default: '0%' },
    placeHolder: { type: String, default: '' },
    maxLength: { type: Number },
    validationRules: { type: Array },
    value: { type: [String, Number, Object], default: '' },
    inputType: { type: String, default: 'text' },
    prependInnerIcon: { type: String },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    message: { type: String },
    mask: {
      type: Object,
      default: () => ({
        toMask: false,
        patterns: [],
      }),
    },
    prefix: { type: String, default: '' },
  },
  data: () => ({
    err: false,
    onlyIntegerPattern: /[0-9]+/g,
    error_message: null,
  }),
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; padding-left: ')
        .concat(this.paddingLeft)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    errorMessage: {
      get() {
        return this.error_message || this.message;
      },
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.inputType === 'number' && this.onlyInteger) {
          value = value.match(this.onlyIntegerPattern).join([]);
        }
        this.validate(value);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.err = typeof result === 'string';
          this.error_message = typeof result === 'string' ? result : null;
        }
        this.$forceUpdate();
      }
    },
    parseMask(value) {
      if (this.mask.toMask) {
        for (let i = 0; i < this.mask.patterns.length; i += 1) {
          value = value.toString().replace(new RegExp(this.mask.patterns[i].from, 'g'), this.mask.patterns[i].to);
        }
        this.textValue = value;
        this.$emit('change');
      }
    },
    focusout() {
      this.$emit('focusout');
    },
    change() {
      this.$emit('change');
    },
  },
});

</script>

<style>
</style>
