<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      max-width="50%"
    >
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="recuse"
          >
            Não
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="accept"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: 'Confirmação de exclusão.',
    },
    description: {
      type: String,
      default: 'Deseja realmente excluir o item?',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {},
  methods: {
    recuse() {
      this.$emit('recuse');
    },
    accept() {
      this.$emit('accept');
    },
  },
});
</script>
