<template>
  <v-dialog v-model="showDialog" max-width="80%" persistent>
    <v-card class="dialog-content">
      <v-card-title style="padding-top: 20px;">
        <h1 data-testid="page-title" class="description">
          Cadastro de Propriedades
        </h1>
        <v-spacer />
        <v-btn color="#07BEE7" icon dark @click="formCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="formValidate">
          <v-row class="margin-fix pa-3">
            <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
              <TextFieldInput
                v-model="form.code"
                label="Código*"
                placeHolder="Informe o ID/código da propriedade"
                labelWidth="40%"
                :validationRules="[codeRules]"
              />
            </v-col>
          </v-row>
          <v-row class="margin-fix pa-3">
            <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
              <TextFieldInput
                v-model="form.name"
                label="Nome*"
                placeHolder="Informe o nome para exibição da propriedade"
                labelWidth="20%"
                :validationRules="[(value) => !!value || 'Campo Nome obrigatório']"
              />
            </v-col>
          </v-row>
          <v-row class="margin-fix pa-3">
            <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
              <ComboboxInput
                v-model="form.type"
                :options="types"
                label="Tipo*"
                placeHolder="Digite ou selecione o tipo da propriedade"
                labelWidth="20%"
                :disabled="!!form.id"
                :validationRules="[(value) => !!value || 'Campo Tipo obrigatório']"
              />
            </v-col>
          </v-row>
          <v-row class="margin-fix pa-3">
            <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
              <SwitchInput :value="form.searchable" v-model="form.searchable" label="Buscável?*" placeHolder="Não" labelWidth="40%" />
            </v-col>
            <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
              <SwitchInput
                :value="form.multiSelectable"
                v-model="form.multiSelectable"
                label="Multiselecionável?*"
                placeHolder="Não"
                labelWidth="40%"
              />
            </v-col>
          </v-row>
          <v-row class="margin-fix pa-3">
            <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
              <SwitchInput
                :value="form.critical"
                v-model="form.critical"
                label="Crítica?*"
                placeHolder="Não"
                labelWidth="40%"
                :disabled="!!$route.params.id"
              />
            </v-col>
            <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
              <SwitchInput :value="form.filtered" v-model="form.filtered" label="Filtrável?*" placeHolder="Não" labelWidth="40%" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions style="padding: 0 30px 30px 30px;">
        <v-btn color="#07BEE7" v-show="false" outlined>Excluir</v-btn>
        <v-spacer />
        <v-btn color="#07BEE7" @click="formCancel" outlined>Cancelar</v-btn>
        <v-btn color="#07BEE7" @click="formSubbmit" outlined :disabled="!formValidate">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import { codeRules } from '@/validations/validation';

export default {
  components: {
    TextFieldInput,
    ComboboxInput,
    SwitchInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cadastro de Propriedade',
    },
    form: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    validation: {
      type: Object,
      required: false,
    },
    formSubbmit: { type: Function },
    formCancel: { type: Function },
    showDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      formValidate: false,
      codeRules,
    };
  },
};
</script>
