<template>
  <v-main class="pa-6">
    <v-row class="margin-fix pa-3">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          v-model="form.code"
          label="Código*"
          placeHolder="Informe o ID/código da propriedade"
          labelWidth="40%"
          :validationRules="[codeRules]"
        />
      </v-col>
      <v-col cols="1">
        <v-spacer />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextFieldInput
          v-model="form.name"
          label="Nome*"
          placeHolder="Informe o nome para exibição da propriedade"
          labelWidth="20%"
          :validationRules="validation.name"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <ComboboxInput
          v-model="form.type"
          :options="types"
          label="Tipo*"
          placeHolder="Digite ou selecione o tipo da propriedade"
          labelWidth="20%"
          :validationRules="validation.version"
          :disabled="!!form.id"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <SwitchInput :value="form.searchable" v-model="form.searchable" label="Buscável?*" placeHolder="Não" labelWidth="40%" />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <SwitchInput :value="form.multiSelectable" v-model="form.multiSelectable" label="Multiselecionável?*" placeHolder="Não" labelWidth="40%" />
      </v-col>
    </v-row>
    <v-row class="margin-fix">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <SwitchInput
          :value="form.critical"
          v-model="form.critical"
          label="Crítica?*"
          placeHolder="Não"
          labelWidth="40%"
          :disabled="!!$route.params.id"
        />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <SwitchInput :value="form.filtered" v-model="form.filtered" label="Filtrável?*" placeHolder="Não" labelWidth="40%" />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import { codeRules } from '@/validations/validation';

export default {
  components: {
    TextFieldInput,
    ComboboxInput,
    SwitchInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cadastro de SKU',
    },
    form: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    validation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      codeRules,
    };
  },
};
</script>
