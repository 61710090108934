import { get, remove, post, put } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export async function getProductTypes(id) {
  return get(`ui-integrator/product-types/${id}`);
}

export async function listProductTypes(param, filter, itemsPerPage, orderBy, sortBy) {
  const { data } = await get(`ui-integrator/product-types/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}
  &order_by=${orderBy ? `${orderBy}` : ''}&sort_by=${sortBy ? `${sortBy}` : ''}`);
  return data;
}

export async function listDadosProductTypes(param, filter, itemsPerPage, orderBy, sortBy) {
  const data = await listProductTypes(param, filter, itemsPerPage, orderBy, sortBy);
  return data.register;
}

export async function countProductTypes() {
  const data = await listProductTypes();
  return data.totalRegister;
}

export async function listProductTypesVariants(param, filter, itemsPerPage) {
  const { data } = await get(`ui-integrator/product-types-variants/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}`);
  return data;
}

export async function listDadosProductTypesVariants(param, filter, itemsPerPage) {
  return listProductTypesVariants(param, filter, itemsPerPage);
}

export async function deleteProductType(productTypeId) {
  const response = await remove(`ui-integrator/product-types/${productTypeId}`);
  return response;
}

export async function listProductType(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);
  if (query.length > 0) query.replaceAll('initialDate =', 'submittedAt >=').replaceAll('finalDate =', 'submittedAt <=');
  const data = await get(`ui-integrator/product-types/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}

export async function createProductType(formData) {
  const data = await post('ui-integrator/product-types/create', formData);
  return data;
}

export async function updateProductType(formData) {
  const data = await put('ui-integrator/product-types/update', formData);
  return data;
}
