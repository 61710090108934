<template>
  <div>
    <v-container class='box-content pa-0 pl-4 pr-4 pt-4' fluid>
      <FormStepController
        :steps="steps"
        v-on:formSubbmit="formSubbmit"
        v-on:formCancel="formCancel"
        :showDeleteActionButton='false'
        :form="form"
        alertScreenTitle="cadastro de tipo de produto"
        backAlertMessage="Deseja cancelar o cadastro deste tipo de produto?"
        :title="!editing ? 'Cadastro de Tipo de Produto' : 'Edição de Tipo de Produto'">
        <GeneralDataForm :previewStatus="previewStatus"
                         :form="form"
                         :statusList="statusList"
                         @validateRequired="validateRequired"
                         :validationRules="steps['GeneralDataForm'].rules"/>
        <PropertiesForm :content='form.properties'
                        v-on:addProperty='addProperty'
                        v-on:deleteProperty="deleteProperty"/>
        <SKUPropertiesForm :content='form.skuProperties'
                           v-on:addSku='addSku'
                           v-on:deleteSku="deleteSku"/>
        <VariantsForm :content='form.variants'
                      v-on:addVariant='addVariant'
                      v-on:deleteVariant="deleteVariant"/>
        <Category :form="form"/>
      </FormStepController>
    </v-container>
    <v-dialog
      v-model='show_dialog'
      transition="dialog-bottom-transition"
      max-width="500"
      content-class="alert-card-dialog">
      <template>
        <v-card color="alert-card">
          <v-card-text>
            <v-row>
              <v-col></v-col>
              <v-col cols="1">
                <v-btn icon dark @click="formCancel" color="primary">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col xs="7" md="5">
                <v-img src="../../../src/assets/images/PublishImg.svg" class="alert-warning-img" />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col xs="6" md="4">
                <p class="alert-warning-title">
                  Atenção!
                </p>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col xs="12" md="10">
                <div class="alert-warning-text">
                  <p>O tipo de produto cadastrado pode ser publicado.</p>
                  <br />
                  <p>Deseja publicá-lo?</p>
                  <br />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-row justify="center">
              <v-col xl="2" lg="2"> </v-col>
              <v-col xs="10" md="10">
                <div class="alert-warning-actions">
                  <v-btn
                    :disabled="isPublishing"
                    :loading="isPublishing"
                    outlined
                    color="primary"
                    @click="formSubbmitChangeStatus('PUBLISHED')"
                    class="mr-2"
                  >
                    PUBLICAR
                  </v-btn>
                  <v-btn outlined color="primary" dark @click="keepStatus()">
                    MANTER STATUS
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import GeneralDataForm from '@/views/productTypes/forms/GeneralDataForm';
import PropertiesForm from '@/views/productTypes/forms/PropertiesForm';
import SKUPropertiesForm from '@/views/productTypes/forms/SKUPropertiesForm';
import VariantsForm from '@/views/productTypes/forms/VariantsForm';
import Category from '@/views/productTypes/forms/Category';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
import utils from '@/utils/utils';
import { getProductTypes, createProductType, updateProductType } from '@/service/product-types/product-types-service';

export default {
  name: 'ProductTypeForm',
  data: () => ({
    previewStatus: 'DRAFT',
    show_dialog: false,
    isPublishing: false,
    editing: false,
    steps: {
      GeneralDataForm: {
        step: 1,
        status: {},
        required: {
          code: false,
          name: true,
        },
        rules: {
          code: (value) => {
            if (!value) {
              return 'Campo `Código` é Obrigatório.';
            }
            return !!value.match(/^[-_A-z0-9]*$/g) || 'O Padrão é Inválido.';
          },
          name: (value) => (!!value) || 'Campo `Nome` é Obrigatório.',
        },
      },
      PropertiesForm: {
        step: 2,
        status: {},
        required: {},
        rules: {},
      },
      SKUPropertiesForm: {
        step: 3,
        status: {},
        required: {},
        rules: {},
      },
      VariantsForm: {
        step: 4,
        status: {},
        required: {},
        rules: {},
      },
      Category: {
        step: 5,
        status: {},
        required: {},
        rules: {},
      },
    },
    form: {
      code: '',
      status: { code: 'DRAFT', name: 'Em Rascunho' },
      version: '1',
      name: '',
      showDimensionTable: false,
      properties: [],
      skuProperties: [],
      variants: [],
      categories: [],
    },
    statusList: [
      { code: 'DRAFT', name: 'Em Rascunho', color: 'orange' },
      { code: 'PUBLISHED', name: 'Publicado', color: 'green' },
      { code: 'UNPUBLISHED', name: 'Não Publicado', color: 'gray' },
    ],
  }),
  components: {
    FormStepController,
    GeneralDataForm,
    PropertiesForm,
    SKUPropertiesForm,
    VariantsForm,
    Category,
  },
  mounted() {
    if (this.$route.params.id) {
      this.getProductType(this.$route.params.id);
    } else {
      this.form.code = `${Math.floor(Math.random() * 1000000) + 1}`;
    }
  },
  methods: {
    async getProductType(id) {
      try {
        const { data } = await getProductTypes(id);
        this.previewStatus = data.status;
        for (let i = 0; i < this.statusList.length; i += 1) {
          if (this.statusList[i].code === data.status) {
            data.status = this.statusList[i];
          }
        }
        this.form = data;
        for (let i = 0; i < this.form.properties.length; i += 1) {
          this.form.properties[i].itemId = this.form.properties[i].id;
        }
        for (let i = 0; i < this.form.skuProperties.length; i += 1) {
          this.form.skuProperties[i].itemId = this.form.skuProperties[i].id;
        }
        for (let i = 0; i < this.form.variants.length; i += 1) {
          this.form.variants[i].itemId = this.form.variants[i].id;
        }
      } catch (err) {
        notification('Erro ao recuperar o tipo de produto', 'error');
      }
    },
    keepStatus() {
      routerPush('/product-type');
    },
    async formSubbmitChangeStatus(code) {
      this.isPublishing = true;
      try {
        const result = await updateProductType({ ...this.form, status: code });
        if (result.status !== 200) {
            notification(`Mensagem: ${result.response.data.message}`, 'error');
          } else {
            routerPush('/product-type');
          }
      } catch (error) {
        this.isPublishing = false;
        notification(`Erro ao atualizar o tipo de produto: ${error.response.data.message}`, 'error');
      }
    },
    formSubbmit() {
      if (this.editing) {
        this.updateComplete();
      } else {
        this.createComplete();
      }
    },
    async createComplete() {
      try {
        const result = await createProductType({ ...this.form, status: this.form.status.code });
        if (result.status !== 201) {
          notification(`Mensagem: ${result.response.data.message}`, 'error');
        } else if (this.form.status.code !== 'PUBLISHED' && this.form.status.code !== 'UNPUBLISHED') {
          this.show_dialog = true;
        } else {
          routerPush('/product-type');
        }
      } catch (error) {
        notification(`Erro ao criar o tipo de produto: ${error.response.data.message}`, 'error');
      }
    },
    async updateComplete() {
      try {
        const result = await updateProductType({ ...this.form, status: this.form.status.code });
        if (result.status !== 200) {
            notification(`Mensagem: ${result.response.data.message}`, 'error');
          } else if (this.form.status.code !== 'PUBLISHED' && this.form.status.code !== 'UNPUBLISHED') {
            this.show_dialog = true;
          } else {
            routerPush('/product-type');
          }
      } catch (error) {
        notification(`Erro ao atualizar o tipo de produto: ${error.response.data.message}`, 'error');
      }
    },
    formCancel() {
      routerPush('/product-type');
    },
    listToIds(list) {
      const ids = [];
      if (list) {
        for (let i = 0; i < list.length; i += 1) {
          ids.push(list[i].id);
        }
      }
      return ids;
    },
    addProperty(property) {
      if (property.itemId == null) {
        this.form.properties.push({
          ...property,
          itemId: Math.random().toString(36).substr(2, 20),
        });
      } else {
        for (let i = 0; i < this.form.properties.length; i += 1) {
          if (property.itemId === this.form.properties[i].itemId) {
            this.form.properties[i] = property;
          }
        }
      }
    },
    addSku(sku) {
      if (sku.itemId == null) {
        this.form.skuProperties.push({
          ...sku,
          itemId: Math.random().toString(36).substr(2, 20),
        });
      } else {
        for (let i = 0; i < this.form.skuProperties.length; i += 1) {
          if (sku.itemId === this.form.skuProperties[i].itemId) {
            this.form.skuProperties[i] = sku;
          }
        }
      }
    },
    addVariant(variant) {
      if (variant.itemId == null) {
        this.form.variants.push({
          ...variant,
          itemId: Math.random().toString(36).substr(2, 20),
        });
      } else {
        for (let i = 0; i < this.form.variants.length; i += 1) {
          if (variant.itemId === this.form.variants[i].itemId) {
            this.form.variants[i] = variant;
          }
        }
      }
    },
    deleteProperty(property) {
      if (property.itemId !== null) {
        for (let i = 0; i < this.form.properties.length; i += 1) {
          if (property.itemId === this.form.properties[i].itemId) {
            this.form.properties.splice(i, 1);
          }
        }
      }
    },
    deleteSku(sku) {
      if (sku.itemId !== null) {
        for (let i = 0; i < this.form.skuProperties.length; i += 1) {
          if (sku.itemId === this.form.skuProperties[i].itemId) {
            this.form.skuProperties.splice(i, 1);
          }
        }
      }
    },
    deleteVariant(variant) {
      if (variant.itemId !== null) {
        for (let i = 0; i < this.form.variants.length; i += 1) {
          if (variant.itemId === this.form.variants[i].itemId) {
            this.form.variants.splice(i, 1);
          }
        }
      }
    },
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);
      utils.revalidate(this);
    },
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },
};
</script>

<style scoped>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}

.alert-card {
  width: 500px;
}

.alert-card-dialog {
  overflow-y: visible;
}

.alert-warning-img {
  width: 150px;
  height: 150px;
  margin: auto;
}

.alert-warning-title {
  width: 100%;
  text-align: center;
  font: normal normal medium 20px/24px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #23272b;
  opacity: 1;
}

.alert-warning-title p {
  margin-bottom: 0px;
}

.alert-warning-text p {
  margin-bottom: 0px;
  text-align: center;
  font: normal normal normal 16px/19px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #7a7e83;
  opacity: 1;
}

.alert-warning-text {
  margin-bottom: 10px;
}

.alert-warning-actions {
  margin-bottom: 40px;
}
</style>
